/**
 * Created by orb-it-dev25 on 01.09.2017.
 */
ymaps.ready(function () {
    var myMap = new ymaps.Map('map', {
            center: [55.908162, 37.408247],
            zoom: 10
        }, {
            searchControlProvider: 'yandex#search'
        }),

        // Создаём макет содержимого.
        MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="toyota_icon" data-dealer-code="" >\
                <div class="icon_header">$[properties.iconContent]</div>\
            </div>'
        ),

        myPlacemark = new ymaps.Placemark(myMap.getCenter(), {
            hintContent: 'Тойота Центр Шереметьево',
            balloonContent: 'Тойота Центр Шереметьево'
        }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#image',
            // Своё изображение иконки метки.
            iconImageHref: 'images/myIcon.gif',
            // Размеры метки.
            iconImageSize: [201, 27],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-100, -40]
        }),

        myPlacemarkWithContent = new ymaps.Placemark([55.908162, 37.408247], {
            hintContent: 'Тойота Центр Шереметьево',
            balloonContent: 'Тойота Центр Шереметьево',
            iconContent: 'Тойота Центр Шереметьево'
        }, {
            // Опции.
            // Необходимо указать данный тип макета.
            iconLayout: 'default#imageWithContent',
            // Своё изображение иконки метки.
            iconImageHref: 'images/ball.png',
            // Размеры метки.
            iconImageSize: [170, 48],
            // Смещение левого верхнего угла иконки относительно
            // её "ножки" (точки привязки).
            iconImageOffset: [-100, -40],
            // Смещение слоя с содержимым относительно слоя с картинкой.
            iconContentOffset: [0, 0],
            // Макет содержимого.
            iconContentLayout: MyIconContentLayout
        });

    myMap.geoObjects
        .add(myPlacemark)
        .add(myPlacemarkWithContent);
});